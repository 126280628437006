import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/middleware/auth';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue')
  },

  // Usuários
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: () => import('@/components/cadastro/Cadastro.vue')
  },
  {
    path: '/login',
    name: 'LoginV3',
    component: () => import('../views/LoginV3.vue')
  },
  {
    path: '/alterar-senha',
    name: 'AlterarSenha',
    component: () => import('../views/AlterarSenha.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/Dashboard.vue')
  },
  {
    path: '/usuario/:id',
    name: 'MeuPerfil',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/usuario/MeuPerfil.vue')
  },

  // PETS
  {
    path: '/cadastrar-pet',
    name: 'CadastroNovoPet',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/pets/CadastroNovoPet.vue')
  },

  // Clientes
  {
    path: '/pet/:id',
    name: 'Pet',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/pets/Pet.vue')
  },
  {
    path: '/pet-publico/:id',
    name: 'CardPublico',
    component: () => import('@/components/pets/CardPublico.vue')
  },
  {
    path: '/avaliacao/:id',
    name: 'Avaliacao',
    component: () => import('@/views/Avaliacao.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/components/geral/FAQ.vue')
  },
  {
    path: '/sugestoes',
    name: 'Sugestoes',
    component: () => import('@/components/geral/Sugestoes.vue')
  },

  // Indicações
  {
    path: '/minhas-indicacoes',
    name: 'Indicacoes',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/indicacoes/Indicacoes.vue')
  },


  // Veterinários
  {
    path: '/veterinarios',
    name: 'Veterinarios',
    component: () => import('@/components/veterinarios/Veterinarios.vue')
  },
  {
    path: '/cadastro-veterinario',
    name: 'CadastroVeterinario',
    component: () => import('@/components/veterinarios/CadastroVeterinario.vue')
  },
  {
    path: '/solicitacoes-veterinarios',
    name: 'SolicitacoesVeterinarios',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/SolicitacoesVeterinarios.vue')
  },
  {
    path: '/ativar-veterinarios',
    name: 'AtivarVeterinario',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/AtivarVeterinario.vue')
  },
  {
    path: '/lista-veterinarios',
    name: 'ListaVeterinarios',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/ListaVeterinarios.vue')
  },

  // Empresas Afiliadas
  {
    path: '/empresas',
    name: 'EmpresasAfiliadas',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/afiliados/EmpresasAfiliadas.vue')
  },

  // Painel de admin
  {
    path: '/visualizar-pedido/:id',
    name: 'VisualizarPedido',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/VisualizarPedido.vue')
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/Pedidos.vue')
  },
  {
    path: '/solicitacoes-saque',
    name: 'SolicitacoesDeSaque',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/afiliados/SolicitacoesDeSaque.vue')
  },
  {
    path: '/depositos-pagos',
    name: 'ListaDepositosPagos',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/ListaDepositosPagos.vue')
  },
  {
    path: '/empresas-afiliadas',
    name: 'ListaEmpresasAfiliadas',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/ListaEmpresasAfiliadas.vue')
  },
  {
    path: '/afiliado',
    name: 'CadastroAfiliado',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/CadastroAfiliado.vue')
  },
  {
    path: '/solicitacoes-empresas',
    name: 'SolicitacoesEmpresas',
    meta: {
      middleware: auth
    },
    component: () => import('@/components/admin/SolicitacoesEmpresas.vue')
  },
  {path: '/:catchAll(.*)*', component: () => import('@/components/geral/PageNotFound.vue')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
  
router.beforeEach((to, from, next) => {
 if (to.meta.middleware) {
   const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
  
    const context = {
      from,
      next,
      router,
      to,
    };
    
   const nextMiddleware = nextFactory(context, middleware, 1);
  
   return middleware[0]({ ...context, next: nextMiddleware });
 }
  
 return next();
});
export default router
