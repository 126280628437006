import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import { Constants } from '@/assets/js/constants.js';
import AOS from 'aos';
import VueTheMask from 'vue-the-mask'
import ConfirmationService from 'primevue/confirmationservice';

//CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import '/node_modules/primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'aos/dist/aos.css';

// ROTA DO BACKEND
axios.defaults.baseURL = 'https://infospet.lksoftware.com.br/public/api';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

const app = createApp(App)
app.config.globalProperties.axios=axios
app.config.globalProperties.$constants = Constants;
app.use(store)
app.use(router)
app.use(PrimeVue)
app.use(ToastService);
app.use(VueSweetalert2)
app.use(AOS.init())
app.use(VueTheMask);
app.use(ConfirmationService);
app.mount('#app')